import React from 'react';
import dynamic from 'next/dynamic';

import { heights } from '#helpers/scroll-to';

import styles from './index.module.scss';

const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const Typography = dynamic(() => import('#app/UI/atoms/new-typography'));

const dataValueProposal = [
  {
    title: 'Formación Integral Digital',
    description: (
      <>
        Educamos en{' '}
        <span className={styles.bold}>habilidades digitales y socio-ocupacionales.</span>
      </>
    ),
    img: './home/value-proposal/image_1.webp'
  },
  {
    title: 'Currícula Adaptada al Mercado',
    description: (
      <>
        Contamos con una{' '}
        <span className={styles.bold}>
          {' '}
          currícula alineada a las demandas del mercado laboral actual.
        </span>
      </>
    ),
    img: './home/value-proposal/image_2.webp'
  },
  {
    title: 'Alianzas público/privadas',
    description: (
      <>
        Establecemos{' '}
        <span className={styles.bold}> alianzas con gobiernos, empresas y más.</span>
      </>
    ),
    img: './home/value-proposal/image_3.webp'
  },
  {
    title: 'Red de profesores',
    description: (
      <>
        Contamos con una{' '}
        <span className={styles.bold}>red de profesores en toda LATAM.</span>
      </>
    ),
    img: './home/value-proposal/image_4.webp'
  },
  {
    title: 'Análisis de impacto continuo',
    description: (
      <>
        Realizamos <span className={styles.bold}>análisis de impacto</span> y
        proporcionamos{' '}
        <span className={styles.bold}>informes de resultados periódicos.</span>
      </>
    ),
    img: './home/value-proposal/image_5.webp'
  },
  {
    title: 'Empleabilidad',
    description: (
      <>
        Ofrecemos mentorías opcionales para{' '}
        <span className={styles.bold}>brindar apoyo en la búsqueda de empleo.</span>
      </>
    ),
    img: './home/value-proposal/image_6.webp'
  }
];
const CardValueProposal = ({ title, description, img }) => {
  return (
    <div className={styles.card}>
      <img className={styles.image} src={img} alt={title} />
      <div className={styles.text}>
        <Typography className={styles.cardTitle} tag="p">
          {title}
        </Typography>
        <Typography className={styles.cardDescription} tag="p">
          {description}
        </Typography>
      </div>
    </div>
  );
};

const ValueProposal = () => {
  return (
    <section className={styles.container}>
      <div className={styles.body}>
        <Typography className={styles.title} tag="h2">
          Nuestra <span className={styles.mainColor}>propuesta de valor</span>
        </Typography>
        <div className={styles.containerCards}>
          {dataValueProposal.map((value, idx) => (
            <CardValueProposal key={`card-value-proposal-${idx}`} {...value} />
          ))}
        </div>
      </div>
      <Button
        className={styles.button}
        variant="orange"
        type="scroll"
        scrollOptions={{
          to: 'programs-for-organizations',
          offset: {
            mobile: {
              hidden: -heights.mobile.header,
              visible: -120
            },
            desktop: {
              hidden: -heights.desktop.header,
              visible: -100
            }
          }
        }}
        textOptions={{ fontSize: [20, 20], color: '#001F19' }}
      >
        Conversemos
      </Button>
    </section>
  );
};

export default ValueProposal;
